import clsx from "clsx";
import { CustomCheckboxContainer, CustomCheckboxInput } from "@reach/checkbox";
import type { ChangeEventHandler } from "react";
import { Paragraph } from "../ui/typography";
interface TagProps {
	tag: string;
	label?: string;
	count?: number;
	selected: boolean;
	onClick?: ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
}

export function CategoryTag({
	tag,
	selected,
	onClick,
	count,
	disabled,
}: TagProps) {
	return (
		<div className="group/tag">
			<CustomCheckboxContainer
				as="label"
				checked={selected}
				onChange={onClick}
				className={clsx(
					"flex overflow-x-hidden rounded text-sm group-hover/tag:bg-grey-20",
					{
						"bg-grey-90": selected,
						"bg-grey-5": !selected,
						"cursor-pointer": !disabled,
						"opacity-30": disabled,
						"group-hover/tag:bg-grey-5": disabled,
						"group-hover/tag:bg-grey-90": selected,
					}
				)}
				disabled={disabled}
			>
				<CustomCheckboxInput
					checked={selected}
					value={tag}
					className="sr-only"
				/>
				<div className="flex items-center gap-3 px-3 py-2">
					<Paragraph
						as="span"
						size="body-small"
						fontWeight="font-medium"
						color={selected ? "inverse" : "primary"}
					>
						{tag}
					</Paragraph>
					{count ? (
						<Paragraph
							as="span"
							size="body-small"
							fontWeight="font-medium"
							color="secondary"
							className={clsx(
								"bg-primary inline-flex h-6 w-7 items-center justify-center rounded"
							)}
						>
							{count}
						</Paragraph>
					) : null}
				</div>
			</CustomCheckboxContainer>
		</div>
	);
}
